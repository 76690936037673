import { StoryblokComponent } from "@storyblok/react";
import ErrorBoundary from "components/shared/ErrorBoundary";
import { Loading } from "components/shared/LoadingPage/Loading";
// import { Loading } from "components/shared/LoadingPage/Loading";
import { useTranslationsContext } from "contexts/translations";
import { WithContexts } from "contexts/withContexts";
import { useEffect } from "react";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { useStoryContent } from "./services/hooks/useStoryContent";
import { Component } from "./services/model/global";
import { EVENT_KEY, WebviewBridge } from "./services/webviewBridge";
import { IndexedDBContextProvider, useIndexedDBContext } from "contexts/IndexedDB";

const App = () => {
  const { languageIsReady } = useTranslationsContext();
  const {loading} = useIndexedDBContext()
  useEffect(() => {
    WebviewBridge.post(EVENT_KEY.APP_READY);
  }, []);

  const story = useStoryContent();
  
  // NOT IN USE
  // WebviewBridge.post(
  //   EVENT_KEY.DEBUG,
  //   `STORY LOADED : ${story?.name ?? "no story"}`
  // );
  
  if (!story || !story.content || !languageIsReady || loading) {
    return <Loading/>;
   
  }

  // If story is there, use StoryblokComponent to render it (dynamically)
  return (
    <div className="scroll-container">
      <div className="scroll-content"> 
      <StoryblokComponent blok={story.content} />
      </div>
    </div>
    
      
    
  );
};

const WithPathname: Component = (props) => {
  // const pathnameArray = window.location.pathname
  //   .split("/")
  //   .filter((t) => t !== "");
  // if (!pathnameArray?.length || !props.children) {
  //   const l = (str: string) => () =>
  //     window.location.assign(`${window.location.origin}/${str}`);
  //   return (
  //     <div className="m-4">
  //       <h1 className="p-4">No Selection, pick one</h1>
  //       <button
  //         onClick={l("fitness-first/default-home-page?language=th")}
  //         className="m-4 bg-slate-300 flex items-center justify-center"
  //         style={{ width: "200px", height: "40px" }}
  //       >
  //         Fitness First Indonesia
  //       </button>
  //       <button
  //         onClick={l("gofit/default-home-page")}
  //         className="m-4 bg-slate-300 flex items-center justify-center"
  //         style={{ width: "200px", height: "40px" }}
  //       >
  //         Gofit
  //       </button>
  //       <button
  //         onClick={l("third-space/default-home-page")}
  //         className="m-4 bg-slate-300 flex items-center justify-center"
  //         style={{ width: "200px", height: "40px" }}
  //       >
  //         Third Space
  //       </button>
  //     </div>
  //   );
  // }
  return <>{props.children}</>;
};

const router = createHashRouter([
  {
    path: "*",
    element: <App />,
  },
]);

const AppWithPathname = () => {
  return (
    <ErrorBoundary>
      <IndexedDBContextProvider>
      <WithPathname>
        <WithContexts>
          <RouterProvider router={router} />
        </WithContexts>
      </WithPathname>
      </IndexedDBContextProvider>
    </ErrorBoundary>
  );
};

export default AppWithPathname;
