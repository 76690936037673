import type { PropsWithChildren } from "react";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

type Asset = {fileUrl:string, base64: string}
type AssetMap = Asset[]

const clearStorage = (store: IDBObjectStore) => {
    const date = new Date();
    const nowDay = `${date.getDate()}`
    const idbSetDate = localStorage.getItem("idb_set_date")

    let searchParams = new URLSearchParams(document.location.search);
    if (nowDay !== idbSetDate || searchParams.get("emptydb")) {
        localStorage.setItem("idb_set_date", nowDay)
        store.clear()
    }
}

const useProvideContext = () => {
  const [assetsMap, setAssetsMap] = useState<AssetMap>([])
  
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const dbOpenRequest = window.indexedDB.open('AssetsDatabase', 1)

    dbOpenRequest.onupgradeneeded = () => {
        if (!dbOpenRequest.result.objectStoreNames.contains('assets')) {
            dbOpenRequest.result.createObjectStore('assets', {keyPath: 'fileUrl'})
        }
        
    }

    dbOpenRequest.onsuccess = () => {
        const db = dbOpenRequest.result
        const transaction = db.transaction('assets', 'readwrite')
        const store = transaction.objectStore('assets')
        clearStorage(store)
        const assetsRequest = store.getAll()
        assetsRequest.onsuccess = () => {
            const response = assetsRequest.result.map<Asset>(({ fileUrl, base64 }) => ({ fileUrl, base64 }));
            setAssetsMap(response)
            setLoading(false)
        }
    }
  }, [])

  const insertAsset = useCallback((fileUrl: string, base64: string) => {
    const data = { fileUrl, base64 };
    const dbOpenRequest = window.indexedDB.open('AssetsDatabase', 1)

    dbOpenRequest.onsuccess = () => {
        const db = dbOpenRequest.result
        const transaction = db.transaction('assets', 'readwrite')
        const store = transaction.objectStore('assets')
        store.add(data);

        setAssetsMap(m => [...m, data])
    }
  }, [])

  const getAssetByUrl = useCallback((fileUrl: string) => {
    return assetsMap.find(a => a.fileUrl === fileUrl)
  }, [assetsMap])

  return useMemo(
    () => ({
        insertAsset,
        getAssetByUrl,
        loading
    }),
    [getAssetByUrl, insertAsset, loading]
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Context = createContext<ReturnType<typeof useProvideContext>>({} as any);

export const IndexedDBContextProvider = ({ children }: PropsWithChildren) => {
  const context = useProvideContext();
  return !context.loading ? <Context.Provider value={context}> {children} </Context.Provider> : null;
};

export const useIndexedDBContext = () => useContext(Context);
