import { SbBlokData, SbBlokKeyDataTypes } from "@storyblok/js";
import { StoryblokAsset } from "./model/storyblok";
import { useEffect, useMemo, useState } from "react";
import { useIndexedDBContext } from "contexts/IndexedDB";
import { useSearchParams } from "react-router-dom";

export const blokDataToString = (blokData: SbBlokKeyDataTypes) => {
    return blokData as string|undefined
}

export const blokDataToArray = (blok: SbBlokData, key: string) => {
    return ((blok?.[key] as unknown as Array<unknown>)?.length ? blok?.[key] : []) as Array<SbBlokData>
}

export const useAssetImage = (blok: any, key = "background_image", size = "800x0") => {
    // Use URL search param to enable indexedDB caching (base64)
    const [searchParams] = useSearchParams();
    const useIndexedDB = searchParams.get('usedb') === "1"
    const {getAssetByUrl, insertAsset} = useIndexedDBContext()

    // Properties from storyblok content block
    const imageRaw = blok[key] as StoryblokAsset
    
    const useOptimize = searchParams.get('useoptimise')
    // Use the quality from the block properties if present, or the quality from URL search params if present
    // or 80 as default
    const urlSearchQ = searchParams.get('quality')
    const defaultQuality = urlSearchQ ? parseInt(urlSearchQ) : 80
    const quality = blok["quality"] && blok["quality"] !== "" ? blok["quality"] : defaultQuality

    // Build URL for Storyblok Image Service
    const fileUrl = imageRaw?.filename && useOptimize ? `${imageRaw.filename}/m/${size ? `${size}/` : ""}filters:quality(${quality})` : imageRaw?.filename

    // Image as base64 when using indexedDB, or URL when not using indexedDB
    const [image, setImage] = useState<string|undefined>(useIndexedDB && fileUrl ? getAssetByUrl(fileUrl)?.base64 : fileUrl)
    
    /**
     * useEffect only for indexedDB & base64 usage.
     * If the base64 image was not retrieved from indexedDB
     * Then we fetch the image URL, and we convert it in a base64 string
     */
    useEffect(() => {
        const fetchAsset = async () => {
            if (!image && imageRaw?.filename && useIndexedDB && fileUrl) {
                // replace a with a2 to avoid CORS issues
                const url = imageRaw.filename.replace("https://a-us.storyblok.com", "https://a2-us.storyblok.com")
                const data = await fetch(url);
                const blob = await data.blob();
                try {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        const base64data = reader.result;
                        insertAsset(fileUrl, base64data as string)
                        setImage(base64data as string)
                    }
                } catch (error) {
                    console.error(error)
                }
            }
        }
        fetchAsset()
    }, [image, fileUrl, imageRaw.filename, insertAsset, useIndexedDB])

    return useMemo(() => image ?? "", [image])
    
}