import classNames from "classnames";
import { useAssetImage } from "services/blockData";
import { BlokComponent } from "services/model/storyblok";

interface StoryblokProps {
    icon?: Array<string>
    label?: string
    key?: string
}

export const GofitMenuNavigationItem: BlokComponent = ({ blok }) => {
    const appBlok = blok as unknown as StoryblokProps
    const icon = useAssetImage(appBlok, "icon")

    let className = classNames("flex", "px-8", "text-xl", "gap-5")
    if (blok.key === "signout") {
        className = classNames(className, "mt-auto")
    }
    return (
        <div className={className}>
            <img src={icon} alt="icon" width="25px" className="grayscale"/>
            <div>{`${blok.label}`}</div>
        </div>
        
    )
}