import { storyblokEditable } from "@storyblok/react";
import classnames from "classnames";
import { COLOR, useThemeContext } from "contexts/theme";
import React, { useLayoutEffect, useMemo, useRef } from "react";
import { useStore } from "services/hooks/useStore";
import { LatestNewsItem } from "services/model/latestNews";
import { MarketplaceItem } from "services/model/marketplace";
import { Topic } from "services/model/topic";
import { LatestNewsStore, MarketplaceStore, TopicsStore } from "services/store";
import { EVENT_KEY, WebviewBridge } from "services/webviewBridge";
import { useAssetImage } from "../../../services/blockData";
import { BlokComponent, Dependency, StoryblokAsset, WithParentDependencies } from "../../../services/model/storyblok";

interface StoryblokProps {
  label: string
  font: string
  topics_key?: string
  marketplace_key?: string
  latestNews_key?:string
  text_color?: COLOR
  border_color?: COLOR
  background_image?: StoryblokAsset
  background_color?: COLOR
  isNewsItem?: boolean
  isMarketplaceItem?: boolean
}

type CardItem = {
  eventParam?: string
  eventKey: EVENT_KEY
  displayValue?: string
}
const useCardItem = (topics_key?: string, marketplace_key?:string,latestNews_key?:string, parentDependencies?: Array<Dependency>): CardItem|undefined => {
  const topics = useStore<Array<Topic>>(TopicsStore);
  const marketplaceItems = useStore<Array<MarketplaceItem>>(MarketplaceStore)
  const latestNewsItems = useStore<Array<LatestNewsItem>>(LatestNewsStore)

  return useMemo(() => {
    if (parentDependencies?.includes(Dependency.LATEST_NEWS)) {
      const latestNewsItem = latestNewsItems?.find(({id}) => id === parseInt(latestNews_key ?? ""))
      return {
        eventKey: EVENT_KEY.VIEW_LATEST_NEWS,
        eventParam: latestNewsItem?.title,
        displayValue: latestNewsItem?.title
      }
    }
    if (parentDependencies?.includes(Dependency.MARKETPLACE)) {
      const marketplaceItem = marketplaceItems?.find(({id}) => id === parseInt(marketplace_key ?? ""))
      return {
        eventKey: EVENT_KEY.VIEW_MARKETPLACE_ITEM,
        eventParam: marketplaceItem?.title,
        displayValue: marketplaceItem?.title
      }
    }
    const topic = topics?.find(({ id }) => id === parseInt(topics_key ?? ""))
    return {
      eventKey: EVENT_KEY.VIEW_TOPIC,
      eventParam: topic?.name,
      displayValue: topic?.name
    }
  }, [topics_key, marketplace_key, latestNews_key, parentDependencies, topics, marketplaceItems, latestNewsItems])

  
}

///***
// NOT IN USE
///*/

export const Tile: BlokComponent<WithParentDependencies> = ({ blok, parentDependencies }) => {
  const appBlok = blok as unknown as StoryblokProps

  const { getColor } = useThemeContext();
  const item = useCardItem(appBlok.topics_key, appBlok.marketplace_key, appBlok.latestNews_key, parentDependencies)
  const backgroundImageUrl = useAssetImage(appBlok)
  const ref = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (ref.current && ref.current.style.backgroundImage !== `url("${backgroundImageUrl}")`) {
      ref.current.style.backgroundImage = `url("${backgroundImageUrl}")`
  }
  })

  if (!item?.displayValue) {
    return null
  }

  // Card Dynamic Style
  const style: React.CSSProperties = {
    backgroundPosition: "center",
    backgroundSize: "cover",
    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.3)",
  };

  if (appBlok.text_color) {
    style.color = getColor(appBlok.text_color);
  }

  if (appBlok.background_color) {
    style.backgroundColor = getColor(appBlok.background_color);
  }

  // Card Static Classes
  const className = classnames(
    "rounded-md",
    "bg-slate-700",
    "uppercase",
    "flex",
    "flex-col",
    "items-start",
    "justify-end",
    "gap-2",
    "p-3",
    "flex-1",
    "h-full",
    "font-bold",
    "relative"
  );

  // Text Border Bottom
  const borderColor = getColor(appBlok.border_color);
  const borderStyle: React.CSSProperties = {
    content: "",
    width: "60px",
    height: "2px",
    background: borderColor,
    bottom: "6px",
    left: "6px",
  };

  const onClick = () => {
    if (item) {
      WebviewBridge.post(item.eventKey, item.eventParam);
    } else {
      WebviewBridge.post(EVENT_KEY.DEBUG, `NO FITNESS FIRST CARD ITEM FOUND FOR  ${appBlok.label}`);
    }
  }

  return (
    <>
    {item? (
     <div
     {...storyblokEditable(blok)}
     ref={ref}
     onClick={onClick}
     className={className}
     style={style}
   >
     <span className={`${appBlok.font} `}>{appBlok.label? appBlok.label: `${item?.displayValue}`}</span>
     <div style={borderStyle} />
   </div>) : null
    }
    </>
   
  );
};
