import { useTranslationsContext } from "contexts/translations"
import { useLayoutEffect, useMemo, useRef, useState } from "react"
import { useAssetImage } from "services/blockData"
import { useStore } from "services/hooks/useStore"
import { BlokComponent, StoryblokAsset } from "services/model/storyblok"
import { CarouselBannerStore } from "services/store"
import { EVENT_KEY, WebviewBridge } from "services/webviewBridge"

interface StoryblokProps {
    title: string
    description: string
    background: StoryblokAsset
    type: string
    font: string
    external_url: string
}

interface InputProps {
    elementsLength: number
    index: number
    slideIndex: number
    setSlideIndex: any
    bannerItems: any
}

type BannerItem = {
    eventParam?: string
    eventKey: EVENT_KEY
    title?: string
    subtitle?: string
}

export const BannerCard: BlokComponent<InputProps, StoryblokProps> = ({ blok, index, slideIndex, setSlideIndex,bannerItems }) => {
    const appBlok = blok as unknown as StoryblokProps

    const useBannerItem = (key?: string): BannerItem | undefined => {
    
        return useMemo(() => {
            const bannerItem = bannerItems?.find(({ type } : { type: string } ) => type === key)
            return {
                eventKey: EVENT_KEY.VIEW_CAROUSEL_BANNER || EVENT_KEY.VIEW_CAROUSEL_BANNER_EXTERNAL_LINK,
                eventParam: bannerItem?.type,
                title: bannerItem?.title,
                subtitle: bannerItem?.subtitle
            }
        }, [key])
    }

    const useMobileBanner = (key?: string): BannerItem | undefined => {
        const data = useStore(CarouselBannerStore)
    
        return useMemo(() => {
            const bannerItem = data?.find(({ type } : { type: string } ) => type === key)
            return {
                eventKey: EVENT_KEY.VIEW_CAROUSEL_BANNER,
                eventParam: bannerItem?.type,
                title: bannerItem?.title,
                subtitle: bannerItem?.subtitle
            }
        }, [key, data])
    }

    const { translate } = useTranslationsContext();
    const banner = useBannerItem(appBlok.type)
    const mobileData = useMobileBanner(appBlok.type)
    const backgroundImageUrl = useAssetImage(appBlok, "background", "1500x0")
    const ref = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        if (ref.current && ref.current.style.backgroundImage !== `url("${backgroundImageUrl}")`) {
            ref.current.style.backgroundImage = `url("${backgroundImageUrl}")`
        }
    })

    const [touchStart, setTouchStart] = useState(0)
    const [touchEnd, setTouchEnd] = useState(0)
    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50

    const getBannerPosition = (bannerIndex: number) => {
        const position = bannerIndex - slideIndex;
        return position * 100 + "%";
    };


    const position = (bannerItems?.length ?? 0) > 1 ? getBannerPosition(index) : "";

    const onClickViewCarouselBanner = (type: string, url?: string) => {
        // Carousel banners have two types of behaviour:
        // 1- redirect the user to the external url 
        // 2- redirect them to internal page
        if (appBlok.type === "url") {
            WebviewBridge.post(EVENT_KEY.VIEW_CAROUSEL_BANNER_EXTERNAL_LINK, url) 
        }else{
            WebviewBridge.post(EVENT_KEY.VIEW_CAROUSEL_BANNER, type);
        }
    }

    if (!banner?.eventParam) {
        return null
    }

    return (
        <div
            ref={ref}
            key={appBlok.type}
            className={`flex flex-col justify-end w-full h-full bg-cover rounded-lg text-slate-50 mr-4 absolute top-0 left-0 duration-500 transform translate-x-[${position}]`}
            onTouchStart={e => {
                setTouchEnd(0) // otherwise the swipe is fired even with usual touch events
                setTouchStart(e.targetTouches[0].clientX)
            }}
            onTouchMove={e => setTouchEnd(e.targetTouches[0].clientX)}
            onTouchEnd={() => {
                if (!touchStart || !touchEnd) return
                const distance = touchStart - touchEnd
                const isLeftSwipe = distance > minSwipeDistance
                const isRightSwipe = distance < -minSwipeDistance

                if (isLeftSwipe) {
                    const newIndex = slideIndex + 1 >= (bannerItems?.length ?? 0) ? (bannerItems?.length ?? 0) - 1 : slideIndex + 1
                    setSlideIndex(newIndex)
                }
                else if (isRightSwipe) {
                    const newIndex = slideIndex - 1 < 0 ? 0 : slideIndex - 1
                    setSlideIndex(newIndex)
                }
            }}
            onClick={() =>{ 
                onClickViewCarouselBanner(appBlok.type, appBlok.external_url)}}
            >
            <div
                className="flex w-full h-full justify-between items-end m-6 "
            >
                <div className=" flex-col w-[85%]">
                    <h1 className={`uppercase text-2xl ${appBlok.font}`}>{translate(appBlok.title) ? translate(appBlok.title) : translate(mobileData?.title ?? "")}</h1>
                    <p className={`text-lg uppercase fitness-first-medium-italic mt-1.5`}>{appBlok.description ? appBlok.description : mobileData?.subtitle}</p>
                    <div className=" w-[80px] h-[2px] bg-[white] mt-1 bottom-1.5 left-1.5" />
                </div>
            </div>
        </div>
    )
}