import { storyblokEditable } from "@storyblok/js";
import classNames from "classnames";
import classnames from "classnames";
import { COLOR, useThemeContext } from "contexts/theme";
import { useTranslationsContext } from "contexts/translations";
import { useLayoutEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useTopicsApiData, TopicsSubject } from "services/api/fetchTopicsByCountry";
import { useAssetImage } from "services/blockData";
import { COUNTRY_CODE } from "services/model/country";
import { BlokComponent, Dependency, StoryblokAsset, WithParentDependencies } from "services/model/storyblok";
import { EVENT_KEY, WebviewBridge } from "services/webviewBridge";

interface StoryblokProps {
    label: string
    font: string
    key: string
    text_color?: COLOR
    border_color?: COLOR
    background_image?: StoryblokAsset
    col_span?: number
    row_span?: number
    alignment: "normal" | "self-start" | "self-end" | "center"
}
type CardItem = {
    eventParam?: string
    eventKey: EVENT_KEY
    displayValue?: string
}

const useClassCardItem = (key?: string, parentDependencies?: Array<Dependency>): CardItem | undefined => {
    const location = useLocation()
    const pathnames = location.pathname?.split('/')?.filter((t) => t !== '' && t !== "#") ?? [];
    const countries = Object.values(COUNTRY_CODE).map(v => v as string)
    const countryCode = (countries.find(c => pathnames.includes(c)) as COUNTRY_CODE) ?? COUNTRY_CODE.INDONESIA
    const classTopics = useTopicsApiData(TopicsSubject, countryCode)

    return useMemo(() => {
        if (parentDependencies?.includes(Dependency.TOPICS)) {
            const classTopic = classTopics?.find(({ id }) => id === parseInt(key ?? ""))
            return {
                eventKey: EVENT_KEY.VIEW_TOPIC,
                eventParam: classTopic?.name,
                displayValue: classTopic?.name
            }
        }

    }, [key, parentDependencies, classTopics])


}

export const ClassCard: BlokComponent<WithParentDependencies> = ({ blok, parentDependencies }) => {
    const appBlok = blok as unknown as StoryblokProps

    const item = useClassCardItem(appBlok.key, parentDependencies)
    const { getColor } = useThemeContext();
    const { translate } = useTranslationsContext();

    const textColor = getColor(appBlok.text_color);
    const borderColor = getColor(appBlok.border_color);
    const backgroundImageUrl = useAssetImage(appBlok)
    const ref = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        if (ref.current && ref.current.style.backgroundImage !== `url("${backgroundImageUrl}")`) {
            ref.current.style.backgroundImage = `url("${backgroundImageUrl}")`
        }
    })

    const className = classnames(
        "rounded-md",
        "uppercase",
        "flex",
        "flex-col",
        "items-start",
        "justify-end",
        "gap-2",
        "p-3",
        "flex-1",
        "h-full",
        "relative",
        `text-[${textColor}]`,
        "bg-center",
        "bg-cover",
        "shadow-[0_5px_10px_0px_rgba(0,0,0,0.3)]"
    );

    const onClick = () => {
        if (item) {
          WebviewBridge.post(item.eventKey, item.eventParam);
        } else {
          WebviewBridge.post(EVENT_KEY.DEBUG, `NO FITNESS FIRST CARD ITEM FOUND FOR  ${appBlok.label}`);
        }
      }
      if (!item?.displayValue) {
        return null
      }

    return (
        <>
            {item ? (
                <div
                {...storyblokEditable(blok)}
                className={classNames(appBlok.col_span && `col-span-${appBlok.col_span}`, appBlok.row_span && `row-span-${appBlok.row_span}`, `justify-${appBlok.alignment}`)}
              >
                <div
                    {...storyblokEditable(blok)}
                    ref={ref}
                    onClick={onClick}
                    className={className}
                >
                    <span className={`${appBlok.font} text-lg`}>{appBlok.label ? appBlok.label : `${translate(item?.displayValue)}`}</span>
                    <div className={`w-[60px] h-[2px] bg-[${borderColor}] bottom-[6px] left-[6px]`} />
                </div>
                </div>) : null
            }
        </>
    )

}
