
import classNames from "classnames";
import { COLOR, useThemeContext } from "contexts/theme";
import { useTranslationsContext } from "contexts/translations";
import Bell from "styles/icons/notifications_white_24dp.svg"
import Close from "styles/icons/close_black_24dp.svg"
import Qrcode from "styles/icons/qr_code_2_white_24dp.svg"
import Blocked from "styles/icons/block_white_24dp.svg"
import HandStop from "styles/icons/pan_tool_white_24dp.svg"
import ExclamationCircle from "styles/icons/error_white_24dp.svg"
import ExclamationTriangle from "styles/icons/warning_white_24dp.svg"
import { useStore } from "services/hooks/useStore";
import { BlokComponent } from "services/model/storyblok";
import { HeaderNotificationStore, MemberStore, MemberTierStore } from "services/store";
import { EVENT_KEY, WebviewBridge } from "services/webviewBridge";
import { useEffect, useState } from "react";

interface InputProps {
    levelIcon?: string
    defaultProfilePicture: string
    font: string
    headerNotificationsBackgroundColor: string
}



export const MemberHeader: BlokComponent<InputProps> = ({ levelIcon, defaultProfilePicture, font, headerNotificationsBackgroundColor}) => {

    const memberTier = useStore(MemberTierStore);
    const member = useStore(MemberStore);
    const headerNotificationItems = useStore(HeaderNotificationStore);

    const { getColor } = useThemeContext()
    const primaryColor = getColor(COLOR.PRIMARY)

    const { translate } = useTranslationsContext();
    const countryName = member?.membership?.country_code ? translate(`countries_${member.membership?.country_code}_name`) : "No country"

    // we need to apply diffrent style for each part of this text so we need to split it 
    const splitedLevelText = memberTier?.tier.name.split(" ")

    const [avatarUrl, setAvatarUrl] = useState(member?.avatar || defaultProfilePicture);
    const isValidImageUrl = (url: any) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
            img.src = url;
        });
    };
    
    useEffect(() => {
        const timer = setTimeout(() => {
            
            isValidImageUrl(member?.avatar)
            
            .then((isValid) => {
                setAvatarUrl(member?.avatar ?? defaultProfilePicture)
              if (!isValid) {
                setAvatarUrl(defaultProfilePicture);
              }
            })
            .catch((error) => {
              console.error("Error checking image URL:", error);
              setAvatarUrl(defaultProfilePicture);
            });
        },100);
        return () => clearTimeout(timer);
      }, [member, defaultProfilePicture]);

    const onLevelInfoClick = () => {
        WebviewBridge.post(EVENT_KEY.VIEW_LEVEL_INFORMATION);
    };
    const onMembershipInfoClick = () => {
        WebviewBridge.post(EVENT_KEY.VIEW_MEMBERSHIP_INFORMATION);
    };
    const onPersonalDetailClick = () => {
        WebviewBridge.post(EVENT_KEY.VIEW_PERSONAL_DETAILS_INFORMATION);
    };

    const onChooseNotificationClick = (type: string) => {
        WebviewBridge.post(EVENT_KEY.VIEW_HEADER_NOTIFICATION, type);
    };
    const onCloseNotification = (type: string) => {
        WebviewBridge.post(EVENT_KEY.DISMISS_HEADER_NOTIFICATION, type);
    };

    function getNotificationIcon(type: string) {
        switch (type) {
            case "experience_survey":
                return <img src={Bell} alt="" />;
            case "qr_relocation":
                return <img src={Qrcode} alt="" />;
            case "membership_issue":
                return <img src={Blocked} alt="" />;
            case "penalty_active":
                return <img src={HandStop} alt="" />;
            case "singapore_reopening":
                return <img src={ExclamationCircle} alt="" />;
            case "penalty_strikes":
                return <img src={ExclamationTriangle} alt="" />;
            case "enable_notification":
                return <img src={Bell} alt="" />;
            default:
                return null;
        }
    }

    return (
        <>
            <section className="grid grid-cols-3 grid-rows-1 ">
                <div
                    className="flex flex-col items-center justify-center text-sm"
                    onClick={onLevelInfoClick}
                >
                    <img src={`${levelIcon}`} alt="level_icon" className="w-6" />
                    <div>
                        {splitedLevelText?.map((word, index) => {
                            const classObj = {
                                'mr-2': index === 0,
                                'italic': index === 0,
                                'text-2xl': index === 1,
                                [font]: index === 1,
                            };
                            const className = classNames(classObj);

                            return (
                                <span key={index} className={className}>
                                    {word}
                                </span>
                            );
                        })}
                    </div>
                </div>
                <div
                    className="flex flex-col items-center justify-center"
                    onClick={onPersonalDetailClick}
                >
                    <div className={`w-[75px] h-[75px] rounded-full bg-[${primaryColor}] relative shadow-[10px_10px_20px_20px_rgba(10,10,10,0.07)]`}>
                        <div
                            className={`w-[110%] h-[110%] bg-[${primaryColor}] top-[-5%] left-[-5%] rounded-full absolute rounded-full`}
                            style={{
                                backgroundImage: `conic-gradient(white calc(${memberTier?.progress} * 1%), ${primaryColor} 0)`
                            }} />
                        <div
                            className={`rounded-full h-full w-full absolute top-0 left-0 bg-[url("${avatarUrl}")] bg-cover bg-no-repeat bg-top z-100`}
                        />
                    </div>
                </div>
                <div
                    className={` flex flex-col items-center justify-center leading-[17px]`}
                    onClick={onMembershipInfoClick}
                >
                    <div className="text-base fitness-first-bold">{translate("app_home_header_membership_title")}</div>
                    <div >{member?.membership?.member_first_id || "No membership ID"}</div>
                    <div className="italic font-normal">{translate(countryName)}</div>
                </div>
            </section>
            <section className="flex items-center justify-center mt-4">
                <span className={`fitness-first-medium text-2xl mb-5`}>
                    {`${member?.first_name} ${member?.last_name}`}
                </span>
            </section>
            <section>
                {headerNotificationItems?.map((headerNotification) => (
                    !headerNotification.hasMessageBeenDismissed ? (
                        <div
                            className={`flex p-3 my-3 rounded-lg items-center justify-between bg-[${headerNotificationsBackgroundColor}]/[.06]`}
                            key={headerNotification.type}
                        >
                            <div
                                className="flex items-center"
                                onClick={() => onChooseNotificationClick(headerNotification.type)}
                            >
                                <span className="mx-2">
                                    {getNotificationIcon(headerNotification.type)}
                                </span>
                                <span className={`text-lg text-slate-100 `}>
                                    {translate(headerNotification.title)}
                                </span>
                            </div>

                            {headerNotification.canBeDismissed && (
                                <span className="mr-2" onClick={() => onCloseNotification(headerNotification.type)}>
                                    <img src={Close} alt="" />
                                </span>
                            )}
                        </div>
                    ) : null
                ))}
            </section>
        </>
    )
}



